import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {TransitionProps} from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";

interface IConfirmingDialogComponentProps {
    opened: boolean,
    closeHandler: () => void,
    acceptHandler: () => void
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

// function TextMaskCustom(props: TextMaskCustomProps) {
//     const {inputRef, ...other} = props;
//     return (
//         <MaskedInput
//             {...other}
//             ref={(ref: any) => {
//                 inputRef(ref ? ref.inputElement : null);
//             }}
//             mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
//             // mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//             placeholderChar={'\u2000'}
//             showMask
//         />
//     );
// }

export const ConfirmingDialogComponent = (props: IConfirmingDialogComponentProps) => {
    // const [phone, setPhone] = useState("");
    return <Dialog
        open={props.opened}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.closeHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="alert-dialog-slide-title">{"Вы уверены?"}</DialogTitle>
        {/*<DialogContent>*/}
        {/*    <DialogContent id="alert-dialog-slide-description">*/}
                {/*<Input*/}
                {/*    value={phone}*/}
                {/*    onChange={(event) => {*/}
                {/*        setPhone(event.target.value)*/}
                {/*    }}*/}
                {/*    inputComponent={TextMaskCustom as any}*/}
                {/*/>*/}

                {/*<InputMask*/}
                {/*    // mask="+9 (999) 999-99-99"*/}
                {/*    mask="99-99-99"*/}
                {/*    value={phone}*/}
                {/*    disabled={false}*/}
                {/*    onChange={(event) => {*/}
                {/*        setPhone(event.target.value)*/}
                {/*    }}*/}
                {/*    maskChar=" "*/}
                {/*>*/}
                {/*    {() => <TextField*/}
                {/*        id="standard-read-only-input"*/}
                {/*        label="Код подтверждения"*/}
                {/*        defaultValue="Иван"*/}
                {/*    />}*/}
                {/*</InputMask>*/}
                {/*<Typography>Заказ успешно выдан</Typography>*/}
        {/*    </DialogContent>*/}
        {/*</DialogContent>*/}
        <DialogActions>
            <Button onClick={props.closeHandler} color="primary" style={{flex:1}}>
                Нет
            </Button>
            <Button onClick={props.acceptHandler} color="primary" style={{flex:1}}>
                Да
            </Button>
        </DialogActions>
    </Dialog>
};