import {ICommonAction} from "../../redux/ICommonAction";
import {IServicesState, state as defaultState} from "./state";
import {actionTypes} from "./actionTypes";
import {IService} from "@shift-mono/common";

export const reducer = (state: IServicesState = defaultState, action: ICommonAction) => {
    switch (action.type) {
        case actionTypes.ADD_SERVICE:
            const service: IService = action.payload.service;
            return {...state, services: {...state.services, [service.getId()]: service}};
        case actionTypes.SET_ERROR_STATUS:
            const errorStatus: boolean = action.payload.errorStatus;
            return {...state, error: errorStatus};
        default:
            return state;
    }
};