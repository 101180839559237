import {takeEvery, put, call, all, select, cancel} from "redux-saga/effects";

import {Logger} from "@shift-mono/common";
import {IOrder, OrderStatus} from "@shift-mono/common";
import {addOrder, getOrder as getOrderAction, receiveOrderPage, setCurrentPage, setErrorStatus, setItemsCount, setLoadingStatus} from "./actions";
import {actionTypes} from "./actionTypes";
import {ICommonAction} from "../../redux/ICommonAction";
import {verificationAndGetAccessToken} from "../Auth";
import {
    requestOrders,
    ICountableResponse,
    AuthorizedRequestData,
    requestOrderById,
    IDataResponse,
    updateOrderStatus as updateOrderStatusRequest
} from "@shift-mono/common";
import {IRootState} from "../../redux/defaultState";

export function* ordersSaga() {
    yield takeEvery(actionTypes.GET_ORDERS, getOrders);
    yield takeEvery(actionTypes.GET_ORDER, getOrder);
    yield takeEvery(actionTypes.REQUEST_PAGE, requestPage);
    yield takeEvery(actionTypes.UPDATE_ORDER_STATUS, updateOrderStatus);
}

function* getOrders(action: ICommonAction) {
    const token = yield call(verificationAndGetAccessToken);
    let params: any = {limit: 0, skip: 0}

    const requestData = new AuthorizedRequestData(token, {}, params);
    const ordersRequestResult: ICountableResponse<IOrder[]> = yield call(requestOrders, requestData);
    const orders = ordersRequestResult.getData();
    try {
        yield all(orders.map((order: IOrder) => put(addOrder(order))));
    } catch (err) {
        Logger.d(err.message)
    }
}

function* getOrder(action: ICommonAction) {
    yield put(setLoadingStatus(true));
    yield put(setErrorStatus(false));

    const orderId = action.payload.orderId;
    const orders = yield select((state: IRootState) => state.orders.orders);
    let order = orders[orderId];
    // if (order) {
    //     Logger.d("GetOrderSaga: order already exist in state");
    //     yield put(setLoadingStatus(false));
    //     yield cancel();
    // }
    Logger.d("GetOrderSaga: get order from server");
    const token = yield call(verificationAndGetAccessToken);
    const requestData = new AuthorizedRequestData(token);
    const orderResponse: IDataResponse<IOrder | undefined> = yield call(requestOrderById, orderId, requestData);
    order = orderResponse.getData();
    if (order) {
        yield put(addOrder(order));
        yield put(setLoadingStatus(false));
        yield cancel();
    }
    yield put(setErrorStatus(true));
    yield put(setLoadingStatus(false));
}

interface IPaginationCalcResult {
    skip: number;
    limit: number;
}

function calcSkipAndLimit(firstElementIndex: number, totalCount: number, countPerList: number): IPaginationCalcResult {
    let skip = (totalCount - firstElementIndex) - countPerList;
    let limit = countPerList;
    if (skip < 0) {
        limit = limit + skip;
        skip = 0;
    }
    return {
        skip: skip,
        limit: limit
    }
}

function sortOrdersByDate(a: IOrder, b: IOrder): number {
    const ACreateDate = a.getCreatedDate();
    const BCreateDate = b.getCreatedDate();
    if (!ACreateDate && !BCreateDate) {
        return 0
    }
    if (!ACreateDate && BCreateDate) {
        return 1
    }
    if (ACreateDate && !BCreateDate) {
        return -1
    }

    return (ACreateDate! > BCreateDate!)
        ? -1
        : (ACreateDate! < BCreateDate!)
            ? 1
            : 0
}

function* requestPage(action: ICommonAction) {
    const pageNumber = Number.parseInt(action.payload.page);
    const itemsPerPage = yield select((state: IRootState) => state.orders.pagination.itemsPerPage);
    const storagePointIds: string[] | null = action.payload.storagePointsId;

    yield put(setErrorStatus(false))

    let params: any = {};
    if (storagePointIds) {
        params = {
            ...params,
            query: `{$and:[{
                        $or:[
                            {storagePointFromId:{$in:[${storagePointIds.map((id) => "\"" + id + "\"")}]}},
                            {storagePointToId:{$in:[${storagePointIds.map((id) => "\"" + id + "\"")}]}}
                        ]},
                        {orderStatus:{$nin:[1]}}]
                    }`
        };
    }
    try {
        const token = yield call(verificationAndGetAccessToken);
        const itemsCount = yield requestOrdersCount(params)
        const res = calcSkipAndLimit((pageNumber * itemsPerPage), itemsCount, itemsPerPage)
        params = {
            ...params,
            limit: res.limit,
            skip: res.skip
        };

        const requestData = new AuthorizedRequestData(token, {}, params);
        const ordersRequestResult: ICountableResponse<IOrder[]> = yield call(requestOrders, requestData);
        let orders = ordersRequestResult.getData();
        const ordersCount = ordersRequestResult.getObjectsCount();
        if (itemsCount !== ordersCount) {
            yield put(setItemsCount(ordersCount));
        }

        orders = orders.sort(sortOrdersByDate)

        try {
            yield all(orders.map((order: IOrder) => put(addOrder(order))));
        } catch (err) {
            Logger.d(err.message)
        }

        yield put(setCurrentPage(pageNumber));
        yield put(receiveOrderPage(pageNumber, orders));
    } catch (err) {
        yield put(setErrorStatus(true))
    }
}

function* requestOrdersCount(additionalParams: any = {}) {
    const token = yield call(verificationAndGetAccessToken);

    let countParams: any = {
        ...additionalParams,
        limit: 1,
        skip: 0
    }

    const countReqData = new AuthorizedRequestData(token, {}, countParams);
    try {
        const countRequestResult: ICountableResponse<IOrder[]> = yield call(requestOrders, countReqData);
        const itemsCount = countRequestResult.getObjectsCount();
        yield put(setItemsCount(itemsCount));
        return yield itemsCount;
    } catch (err) {
        throw err;
    }
}

function* updateOrderStatus(action: ICommonAction) {
    yield put(setErrorStatus(false));
    try {
        const token = yield call(verificationAndGetAccessToken);
        const orderId: string = action.payload.orderId;
        const status: OrderStatus = action.payload.status;
        const requestData = new AuthorizedRequestData(token);
        const result = yield call(updateOrderStatusRequest, orderId, status, requestData);
        if (!result) {
            yield put(setErrorStatus(true));
        }
        yield put(getOrderAction(orderId));
    } catch (err) {
        yield put(setErrorStatus(true));
    }
}