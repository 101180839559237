import {IOrder} from "@shift-mono/common";
import {IHash} from "@shift-mono/common";

//TODO Вынести из стейта IHash и все что связанно с пагинацией

export interface IPage {
    ids: string[];
    fetching: boolean;
}

export interface IPagination {
    itemsCount: number;
    itemsPerPage: number;
    currentPage: number;
    pages: IHash<IPage>;
}

export interface IOrdersState {
    orders: IHash<IOrder>;
    pagination: IPagination;
    loading: boolean;
    error: boolean;
}

export const state: IOrdersState = {
    orders: {},
    loading: false,
    error: false,
    pagination: {
        itemsCount: -1,
        currentPage: 0,
        itemsPerPage: 30,
        pages: {}
    }
};