import React from "react";
import {ReactReduxContext} from "react-redux";
import {Route, Redirect} from "react-router-dom";

import {signIn as signInURL} from "../../../routers/URLs"

interface IPrivateRouteProps {
    exact?: any,
    component: React.ComponentType<any>,
    path: string,
}

export const PrivateRoute = ({component: Component, ...rest}: IPrivateRouteProps) => (
    <Route
        {...rest}
        render={props => {
            return (
                <ReactReduxContext.Consumer>
                    {({store}) => {
                        const state = store.getState();
                        const auth = state["auth"];
                        return auth.signedIn === true ? (
                            <Component {...props} />
                        ) : (
                            <Redirect to={signInURL}/>
                        );
                    }}
                </ReactReduxContext.Consumer>
            );
        }}
    />
);