import {combineReducers} from "redux";

import {reducer as auth} from "../components/Auth"
import {reducer as orders} from "../components/Orders"
import {reducer as clients} from "../components/Clients"
import {reducer as storagePoints} from "../components/StoragePoints";
import {reducer as services} from "../components/Services";

export default combineReducers({
    auth,
    orders,
    clients,
    storagePoints,
    services,
});