import React from "react";
import {Route} from "react-router-dom";
import {Template} from "../Template";
import {OrderListComponentWithRouter, OrderDetailComponent} from "../../components/Orders";

interface IOrderPageProps {
    match: any
}

export const OrderPage = ({match}: IOrderPageProps) => {
    return <Template>
        <Route exact path={`${match.path}/`}><OrderListComponentWithRouter selectOrderHandler={() => {}}/></Route>
        <Route path={`${match.path}/:id`}><OrderDetailComponent backButtonHandler={() => {}}/></Route>
    </Template>
};