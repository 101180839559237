import React from "react";
import {Route} from "react-router-dom";
import {Template} from "../Template";
import {InstructionComponent} from "../../components/Instruction";

interface IInstructionPageProps {
    match: any
}

export const InstructionPage = ({match}: IInstructionPageProps) => {
    return <Template>
        <Route path={`${match.path}/`} component={InstructionComponent}/>
    </Template>
};