import React from "react";
import {Route} from "react-router-dom";
import {Template} from "../Template";
import {AboutCompanyComponent} from "../../components/AboutCompany";

interface IAboutPageProps {
    match: any
}

export const AboutPage = ({match}: IAboutPageProps) => {
    return <Template>
        <Route path={`${match.path}/`} component={AboutCompanyComponent}/>
    </Template>
};