export interface IAuthState {
    message: string | null,
    signedIn: boolean,

    tokenType: string | null,
    scope: string | null,
}

export const state: IAuthState = {
    message: null,
    signedIn: false,

    tokenType: null,
    scope: null,
};