import {all} from "redux-saga/effects";

import {authSaga} from "../components/Auth/";
import {ordersSaga} from "../components/Orders";
import {clientsSaga} from "../components/Clients";
import {storagePointsSaga} from "../components/StoragePoints";
import {servicesSaga} from "../components/Services";

export default function* rootSaga() {
    yield all([
        authSaga(),
        ordersSaga(),
        clientsSaga(),
        storagePointsSaga(),
        servicesSaga(),
    ])
}