import {IHash, IService} from "@shift-mono/common";

export interface IServicesState {
    services: IHash<IService>;
    loading: boolean;
    error: boolean;
}

export const state: IServicesState = {
    services: {},
    loading: false,
    error: false,
}