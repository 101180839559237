import {IClient} from "@shift-mono/common";
import {ICommonAction} from "../../redux/ICommonAction";
import {actionTypes} from "./actionTypes";

//REDUX Actions

export const addClient = (client: IClient) : ICommonAction => {
    return {
        type: actionTypes.ADD_CLIENT,
        payload: {
            client
        }
    }
};

export const setLoadingStatus = (loading: boolean): ICommonAction => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        payload: {
            loading
        }
    }
};

export const setErrorStatus = (errorStatus: boolean): ICommonAction => {
    return {
        type: actionTypes.SET_ERROR_STATUS,
        payload: {
            errorStatus
        }
    }
};

//REDUX-SAGA Actions

export const getClient = (clientId: string): ICommonAction => {
    return {
        type: actionTypes.GET_CLIENT,
        payload: {clientId}
    }
};