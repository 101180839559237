import React, {useState} from "react";
import WorkIcon from '@material-ui/icons/Work';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {Redirect, withRouter, matchPath, RouteComponentProps, NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";

import {signIn, orders, storages, about, instruction, faq} from "./URLs";
import {logout} from "../components/Auth";
import {IRootState} from "../redux/defaultState";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ForumIcon from '@material-ui/icons/Forum';
import ApartmentIcon from '@material-ui/icons/Apartment';

const useStyle = makeStyles(() => ({
        divider: {
            backgroundColor: "rgba(255,255,255,0.12)"
        },
        menuButtonText: {
            color: "#FFFFFF"
        },
        menuButton: {
            textDecoration: "none"
        },
        contactLink: {
            textDecoration: "none"
        },
        contactButtonIcon: {
            color: "#fafafa"
        }

    })
);

const Menu = (props: RouteComponentProps) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const authState = useSelector((state: IRootState) => (state.auth));

    const matchUrl = (url: string) => {
        const currentLocation = props.location.pathname;
        const escapedPath = url && url.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
        return escapedPath
            ? matchPath(currentLocation, {
                path: escapedPath
            })
            : null;
    };

    const [menuItems] = useState([
        {
            iconComponent: <AttachMoneyIcon/>,
            title: "Заказы",
            url: orders,
            number: 1,
            isActive: matchUrl(orders)
        },
        {
            iconComponent: <WorkIcon/>,
            title: "ПВЗ / Отели",
            url: storages,
            number: 2,
            isActive: matchUrl(storages)
        },
        {
            iconComponent: <ContactSupportIcon/>,
            title: "Инструкция",
            url: instruction,
            number: 3,
            isActive: matchUrl(instruction)
        },
        {
            iconComponent: <ForumIcon/>,
            title: "FAQ",
            url: faq,
            number: 4,
            isActive: matchUrl(faq)
        },
        {
            iconComponent: <ApartmentIcon/>,
            title: "О компании",
            url: about,
            number: 5,
            isActive: matchUrl(about)
        },
    ])

    const logoutHandler = () => {
        if (window.confirm("Точно выйти?")) {
            dispatch(logout());
        }
    };

    return (<>
        {!authState.signedIn ? <Redirect to={signIn}/> : <></>}
        <List>
            {menuItems
                .sort((i1, i2) => {
                    if (i1.number < i2.number) {
                        return -1
                    }
                    if (i1.number > i2.number) {
                        return 1
                    }
                    return 0
                })
                .map((item: any, index) => (
                    <NavLink
                        to={item.url}
                        key={item.number}
                        className={classes.menuButton}
                    >
                        <ListItem button key={item.title}>
                            <ListItemText primary={item.title} className={classes.menuButtonText}/>
                            <ListItemAvatar>
                                <Avatar>
                                    {item.iconComponent}
                                </Avatar>
                            </ListItemAvatar>
                        </ListItem>
                    </NavLink>
                ))}
        </List>
        <Divider className={classes.divider}/>
        <List>
            <ListItem button key="logout" onClick={logoutHandler}>
                <ListItemText primary="Выход" className={classes.menuButtonText}/>
                <ListItemAvatar>
                    <Avatar>
                        <ExitToAppIcon/>
                    </Avatar>
                </ListItemAvatar>
            </ListItem>
        </List>
        <Divider className={classes.divider}/>
        <List>
            <a href={"tel:+78003505728"} className={classes.contactLink}>
                <ListItem button>
                    <ListItemIcon>
                        <LocalPhoneIcon className={classes.contactButtonIcon}/>
                    </ListItemIcon>
                    <ListItemText primary="+7(800)350-57-28" className={classes.menuButtonText}/>
                </ListItem>
            </a>
            <a href={"mailto:info@skyshifts.com"} className={classes.contactLink}>
                <ListItem button>
                    <ListItemIcon>
                        <LocalPostOfficeIcon className={classes.contactButtonIcon}/>
                    </ListItemIcon>
                    <ListItemText primary="info@skyshifts.com" className={classes.menuButtonText}/>
                </ListItem>
            </a>
        </List>
    </>)
};

export const MenuComponent = withRouter(Menu);