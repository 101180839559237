import {IStoragePointsState, state as defaultState} from "./state";
import {ICommonAction} from "../../redux/ICommonAction";
import {actionTypes} from "./actionTypes";
import {IStoragePoint} from "@shift-mono/common";

export const reducer = (state: IStoragePointsState = defaultState, action: ICommonAction) => {
    switch (action.type) {
        case actionTypes.ADD_STORAGE_POINT:
            const storagePoint: IStoragePoint = action.payload.storagePoint;
            return {...state, storagePoints: {...state.storagePoints, [storagePoint.getId()]: storagePoint}};
        case actionTypes.SET_ERROR_STATUS:
            const errorStatus: boolean = action.payload.errorStatus;
            return {...state, error: errorStatus};
        default:
            return state;
    }
};