import {IAuthState, state as auth} from "../components/Auth/state"
import {IOrdersState, state as orders} from "../components/Orders";
import {IClientsState, state as clients} from "../components/Clients";
import {IStoragePointsState, state as storagePoints} from "../components/StoragePoints";
import {IServicesState, state as services} from "../components/Services";

export interface IRootState{
    auth: IAuthState;
    orders: IOrdersState;
    clients: IClientsState;
    storagePoints: IStoragePointsState;
    services: IServicesState;
}

const rootState: IRootState = {
    auth,
    orders,
    clients,
    storagePoints,
    services,
};

export default rootState;