import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {Button, CircularProgress, TextField, useTheme} from "@material-ui/core";
import {ClientCardComponent} from "./orderDetailPartials/ClientCardComponent";
import {connect, useDispatch, useSelector} from "react-redux";
import {getOrder, updateOrderStatus as updateOrderStatusAction} from "../actions";
import {IRootState} from "../../../redux/defaultState";
import {ConfirmingDialogComponent} from "./orderDetailPartials/ConfirmingDialogComponent";
import {IHash, IOrder, IService, OrderStatusEnv} from "@shift-mono/common";
import {getOrderStatusTitle} from "@shift-mono/common";
import {OrderStatus} from "@shift-mono/common";
import {requestStoragePoints} from "../../StoragePoints";
import {CarouselProvider, DotGroup, Image, Slide, Slider} from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import {requestServicesByIds} from "../../Services";


export interface IOrderDetailComponentProps {
    backButtonHandler: () => void;
    history: any,
    match: any,
    location: any,
    loading: boolean,
    error: boolean
}

const useStyles = makeStyles({
    backButton: {
        marginTop: '20px'
    },
    dotGroup: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0 0 0',

        '& button': {
            width: '15px',
            height: '15px',
            borderRadius: '10px',
            margin: '0 2px',
            borderWidth: '0px',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        }
    },
    card: {
        margin: '5px',
    },
    button: {
        background: '#16D17F',
        color: '#FFFFFF',
        margin: '5px',

        '&:hover': {
            background: '#0B8E54'
        },
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    imgBackPlate: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.2)',
        top: 0,
        left: 0,
        zIndex: 5000,

        '& img': {
            width: '60vh',
            height: '60vh',
            position: 'fixed',
            top: '50%',
            left: '50%',
            margin: '-30vh 0 0 -30vh',
            boxShadow: '0 0 40px 5px rgba(0, 0, 0, 0.3)',
            transform: 'none',
            zIndex: 5005,

            '@media (max-width: 1000px)': {
                width: '80vw',
                height: '80vw',
                marginTop: '-40vw',
                marginLeft: '-40vw'
            }
        }
    },

});

const OrderDetail = (props: IOrderDetailComponentProps) => {
    const theme = useTheme();
    const styles = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);

    const dispatch = useDispatch();
    const orderId = props.match.params.id;
    const orders = useSelector((state: IRootState) => (state.orders.orders));
    const servicesHash: IHash<IService> = useSelector((state: IRootState) => (state.services.services));
    const relatedStoragePoints = useSelector((state: IRootState) => (state.storagePoints.storagePoints));
    let order: IOrder | undefined = undefined;
    let boundedServicesId: string[] = [];

    const getServiceIdsFromOrder = (order: IOrder): string[] => {
        return order
            .getServices()
            .map((item) => (item.getServiceId()));
    }

    const getServices = (services: IHash<IService>, ids: string[]): IService[] => {
        return ids
            .reduce((result: IService[], id) => {
                if (services[id]) {
                    result.push(services[id]);
                }
                return result;
            }, []);
    }

    if (orderId) {
        order = orders[orderId];
        if (order) {
            boundedServicesId = getServiceIdsFromOrder(order);
        }
    }
    useEffect(() => {
        dispatch(requestStoragePoints());
        dispatch(getOrder(orderId))
    }, [dispatch, orderId])

    useEffect(() => {
        dispatch(requestServicesByIds(boundedServicesId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, boundedServicesId.length])

    const [currentImageSrc, setImageSrc] = useState<string | null>(null);

    const openDialogHandle = () => {
        setDialogOpen(true)
    };

    const checkRelatedStoragePointById = (id: string): boolean => {
        return Object
            .keys(relatedStoragePoints)
            .includes(id)
    }

    const renderConfirmOrderButtonIfNeeded = (order: IOrder) => {
        if (order.getStoragePointFromId()) {
            if (order.getOrderStatus() === OrderStatus.TransferredDelivery
                && checkRelatedStoragePointById(order.getStoragePointFromId()!)) {
                return <Button
                    variant="contained"
                    className={styles.button}
                    onClick={() => {
                        openDialogHandle()
                    }}
                >
                    Подтвердить получение
                </Button>
            }
        }

        if (order.getStoragePointToId()) {
            if (order.getOrderStatus() === OrderStatus.Processing
                && checkRelatedStoragePointById(order.getStoragePointToId()!)) {
                return <Button
                    variant="contained"
                    className={styles.button}
                    onClick={() => {
                        openDialogHandle()
                    }}
                >
                    Подтвердить получение
                </Button>
            }

            if (order.getOrderStatus() === OrderStatus.WaitingDelivery
                && checkRelatedStoragePointById(order.getStoragePointToId()!)) {
                return <Button
                    variant="contained"
                    className={styles.button}
                    onClick={() => {
                        openDialogHandle()
                    }}
                >
                    Выдать заказ
                </Button>
            }
        }
        return <></>
    }

    const updateOrderStatus = (order: IOrder | undefined) => {
        if (!order) {
            return
        }
        if (order.getOrderStatus() === OrderStatus.TransferredDelivery) {
            dispatch(updateOrderStatusAction(order.getId(), OrderStatus.Processing));
        } else if (order.getOrderStatus() === OrderStatus.Processing) {
            dispatch(updateOrderStatusAction(order.getId(), OrderStatus.WaitingDelivery));
        } else if (order.getOrderStatus() === OrderStatus.WaitingDelivery) {
            dispatch(updateOrderStatusAction(order.getId(), OrderStatus.Issued));
        }
    }

    const renderOrderImages = (order: IOrder) => {
        if (!order) {
            return <></>
        }

        const photosUrls = order
            .getServices()
            .map((service) => service.getPhotoUrls())
            .flat()
            .reduce((urlsSet: Set<string>, url) => {
                urlsSet.add(url);
                return urlsSet;
            }, new Set<string>())
            .keys()

        const photoSliders = Array
            .from(photosUrls)
            .map((url, index) => {
                return <Slide key={index} index={index}><Image hasMasterSpinner={false} src={url} onClick={
                    (e: any) => {
                        setImageSrc(e.target.src)
                    }
                }/></Slide>
            })

        if (photoSliders.length === 0) {
            return (<></>)
        }

        return (<>
                {currentImageSrc
                    ? <div
                        className={styles.imgBackPlate}
                        onClick={() => {
                            setImageSrc(null)
                        }}
                    ><img src={currentImageSrc!} alt=""/></div>
                    : <></>}

                <Typography variant="h6">Фото</Typography>
                <Card className={styles.card}>
                    <CardContent>
                        <CarouselProvider
                            naturalSlideWidth={150}
                            naturalSlideHeight={100}
                            totalSlides={photoSliders.length}
                            visibleSlides={3}
                        >
                            <Slider>
                                {photoSliders}
                            </Slider>
                            <DotGroup className={styles.dotGroup}/>
                        </CarouselProvider>
                    </CardContent>
                </Card>
            </>
        )
    }

    const renderOrderData = (order: IOrder) => {
        return (<form noValidate autoComplete="off">
            <Grid container>
                <Grid item md={6} xs={12}>
                    <Grid item xs={12}>
                        <Card className={styles.card}>
                            <CardContent>
                                <div>
                                    <TextField
                                        label="Номер заказа"
                                        defaultValue={order.getShortId()? order.getShortId(): order.getId()}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="Дата создания заказа"
                                        defaultValue={order.getCreatedDate()?.toLocaleDateString()}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={styles.card}>
                            <CardContent>
                                <div>
                                    <TextField
                                        label="Статус"
                                        value={getOrderStatusTitle(order.getOrderStatus(), OrderStatusEnv.B2B)}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="Ориентировочная дата доставки клиенту"
                                        defaultValue={order.getEtaDate()?.toLocaleDateString()}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="Сумма заказа"
                                        defaultValue={order.getTotalPriceWithDiscount()}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        {renderServicesData(getServices(servicesHash, boundedServicesId))}
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    {order.getClientId() ? <ClientCardComponent clientId={order.getClientId()!}/> : (<></>)}
                </Grid>
                <Grid item xs={12}>
                    {renderAddressData(order)}
                </Grid>
            </Grid>
            {renderOrderImages(order)}
            {renderConfirmOrderButtonIfNeeded(order)}
        </form>)
    }

    // const tileData = [
    //     {img: "/images/mock_1.jpg"},
    //     {img: "/images/mock_2.jpg"},
    //     {img: "/images/mock_3.jpg"},
    // ];
    if (props.loading) {
        return <div className={styles.loaderContainer}><CircularProgress/></div>
    }
    if (props.error || !order) {
        return <Typography>Ошибка загрузки данных</Typography>
    }


    const renderAddressData = (order: IOrder) => {
        const addresses: { title: string, value: string }[] = []

        if (order.getAddressFrom() && order.getAddressFrom()!.getFullAddress().trim() !== "") {
            addresses.push({
                title: "Адрес отправки",
                value: order.getAddressFrom()!.getFullAddress()
            });
        }

        if (order.getAddressTo() && order.getAddressTo()!.getFullAddress().trim() !== "") {
            addresses.push({
                title: "Адрес приема",
                value: order.getAddressTo()!.getFullAddress()
            });
        }

        if (addresses.length === 1) {
            addresses[0].title = "Адрес";
        }

        return <> <Card className={styles.card}>
            <CardContent>
                {addresses.map((item) => {
                    return (
                        <div key={item.value}>
                            <TextField
                                label={item.title}
                                value={item.value}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </div>)
                })}
            </CardContent>
        </Card></>
    }

    const renderServicesData = (services: IService[]) => {
        if (services.length === 0) {
            return <></>
        }

        return <>
            <Card className={styles.card}>
                <CardContent>
                    {services.map((service) => {
                        return (
                            <div key={service.getId()}>
                                <TextField
                                    label={"Наименование услуги"}
                                    value={service.getName()}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>)
                    })}
                </CardContent>
            </Card></>
    }

    return <>
        <ConfirmingDialogComponent
            opened={dialogOpen}
            closeHandler={() => {
                setDialogOpen(false)
            }}
            acceptHandler={() => {
                updateOrderStatus(order);
                setDialogOpen(false);
            }}
        />
        <Button
            className={styles.backButton}
            size="small"
            onClick={props.history.goBack}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            Назад
        </Button>
        <Container maxWidth="md">
            {renderOrderData(order)}
        </Container>
    </>
};

const mapStateToProps = (state: IRootState) => {
    return {
        loading: state.orders.loading,
        error: state.orders.error,
    };
}

export const OrderDetailComponent = connect(mapStateToProps)(withRouter(OrderDetail));