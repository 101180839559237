import React, {useEffect} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {connect, useDispatch, useSelector} from "react-redux";
import {CircularProgress, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {IRootState} from "../../../../redux/defaultState";
import Typography from "@material-ui/core/Typography";
import {getClient} from "../../../Clients";
import {IClient} from "@shift-mono/common";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles({
    card: {
        margin: '5px',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    avatarWrap: {
        display: "flex",
        justifyContent: "center"
    },
    avatar: {
        width: "150px",
        height: "150px",
    },
});

interface IClientCardComponentProps {
    clientId: string,
    loading: boolean,
    error: boolean,
}

const ClientCard = (props: IClientCardComponentProps) => {
    const styles = useStyles();

    const dispatch = useDispatch();
    const clientId = props.clientId;
    const clients = useSelector((state: IRootState) => (state.clients.clients));
    let client: IClient | undefined = undefined;
    if (clientId) {
        client = clients[clientId];
    }
    useEffect(() => {
        dispatch(getClient(clientId))
    }, [dispatch, clientId])

    const renderClientInfo = () => {
        if (props.error || !client) {
            return <Typography>Нет данных о клиенте!</Typography>
        }

        let ava = <></>
        if(client.getAvatarUrl().trim() !== ""){
            ava = <Avatar className={styles.avatar} alt="User avatar" src={client.getAvatarUrl()} />
        }

        return (<>
                <div className={styles.avatarWrap}>
                    {ava}
                </div>
                <div>
                    <TextField
                        label="ФИО клиента"
                        value={client.getFullName()}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
                <div>
                    <TextField
                        label="Телефон"
                        value={client.getPhone()}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
            </>
        )
    }

    return (<Card className={styles.card}>
        <CardContent>
            {props.loading
                ? <div className={styles.loaderContainer}><CircularProgress/></div>
                : renderClientInfo()}
        </CardContent>
    </Card>)
};

const mapStateToProps = (state: IRootState) => {
    return {
        loading: state.clients.loading,
        error: state.clients.error,
    }
}

export const ClientCardComponent = connect(mapStateToProps)(ClientCard)