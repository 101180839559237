import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {TableContainer} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    card: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px",
    },
});

interface IRowProps {
    row: IRowData
}

interface IRowData {
    question: string,
    answer: any,
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    answerText: {
        fontSize: "19px",
        marginLeft: "35px",
    }
});

const Row = (props: IRowProps) => {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root} onClick={() => setOpen(!open)}>
                <TableCell style={{width: "10px"}}>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography variant="h6" gutterBottom component="div">
                        {row.question}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant={"body2"} gutterBottom component="div" className={classes.answerText}>
                                {row.answer}
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const createData = (): IRowData[] => {
    return [
        {
            question: "Хочу отправить своё снаряжение с Курорта домой. Что делать?",
            answer: <ol>
                <li>Вам необходимо скачать мобильное приложение SkyShift’s в App Store/Play Маркете и оформить заказ.
                </li>
                <li>Оставляете своё снаряжение/багаж в пункте приема/Отеле и летите домой налегке.</li>
                <li>В течение 15 минут Вам перезвонит оператор для подтверждения заказа, также на всём пути доставки
                    багажа/снаряжения Вам будут сообщать статус доставки.
                </li>
            </ol>
        },
        {
            question: "Хочу отправить своё снаряжение из дома/квартиры на Курорт. Что делать?",
            answer: <>
                <p>Вам необходимо скачать мобильное приложение SkyShift’s в App Store/Play Маркете и оформить
                    заказ.<br/>
                    После оплаты Вам позвонит оператор для подтверждения заказа/уточнение времени.<br/>
                    В назначенное время к Вам подъедет курьер для забора снаряжения/багажа.<br/>
                    По прибытию на курорт Вас уже будет ждать Ваше снаряжение в пункте приема/Отеле.</p>
            </>
        },
        {
            question: "Что входит в стоимость перемещения?",
            answer: <>
                <p>В стоимость перемещения входит:</p>
                <ul>
                    <li>доставка дверь- дверь, т.е. Курьер заберет багаж/снаряжение с Вашей квартиры/дома и доставит
                        прямо в Отель/ПВЗ;
                    </li>
                    <li>упаковка;</li>
                    <li>страховка;</li>
                    <li>служба поддержки 24/7. Мы всегда ответим на ваши вопросы;</li>
                </ul>
            </>
        },
        {
            question: "Вы работаете по всей России?",
            answer: <p>Да</p>
        },
        {
            question: "Как можно оплатить перемещения снаряжения?",
            answer: <>
                <p>Оплатить Вы можете в Мобильном приложении через:</p>
                <ul>
                    <li>Apple Pay;</li>
                    <li>Сбербанк Онлайн;</li>
                    <li>Другая банковская карта;</li>
                </ul>
            </>
        },
        {
            question: "В каком виде отправлять снаряжение? ",
            answer: <>
                <p>Доставка снаряжения принимается только в чехлах.</p>
                <p>Комплектом горнолыжного снаряжения считать:</p>
                <ul>
                    <li>лыжи с палками, лыжные ботинки, шлем, маска;</li>
                    <li>сноуборд с ботинками в чехле, шлем, маска;</li>
                </ul>
            </>
        },
        {
            question: "Есть ограничения по весу и габариту?",
            answer: <p>Нет, нам всё равно какой вес и габариты, мы просто хотим, чтобы Вы жили налегке!!!</p>
        },
        {
            question: "Контактная информация",
            answer: <>
                <p>Сайт: <a href={"https://skyshifts.com/"}>https://skyshifts.com/</a></p>
                <p>Телефоны: <a href={"tel:+78003505728"}>8 (800) 350-57-28</a> <a href={"tel:+79051110008"}>8(905)111-00-08</a></p>
                <p>Мессенджеры: WhatsApp, Telegram, Viber</p>
                <p>Соц. сети:</p>
                <ul style={{listStyleType: "none"}}>
                    <li>Instagram – skyshifts</li>
                    <li>Vkontakte - <a href={"https://vk.com/skyshifts"}>https://vk.com/skyshifts</a></li>
                    <li>Tik Tok – skyshifts</li>
                    <li>Facebook - <a href={"https://www.facebook.com/SkyShifts/"}>https://www.facebook.com/SkyShifts/ @SkyShifts</a></li>
                </ul>
            </>
        },
    ]
}

export const FAQComponent = () => {
    const styles = useStyles();
    const rows = createData();
    return (
        <Container maxWidth="md" className={styles.card}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableBody>
                                    {rows.map((row) => (
                                        <Row key={row.question} row={row}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Container>);
}