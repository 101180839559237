import React from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {home, signIn, orders, storages, about, faq, instruction} from "./URLs";
import {OrderPage} from "./pages/OrderPage";
import {LoginPage} from "./pages/LoginPage";
import {PrivateRoute} from "../components/Auth";
import {StoragePointPage} from "./pages/StoragePointPage";
import {AboutPage} from "./pages/AboutPage";
import {FAQPage} from "./pages/FAQPage";
import {InstructionPage} from "./pages/InstructionPage";

export const Routers = () => {
    return <BrowserRouter>
        <Switch>
            <Route path={signIn} component={LoginPage}/>
            <PrivateRoute exact path={home} component={OrderPage}/>
            <PrivateRoute path={orders} component={OrderPage}/>
            <PrivateRoute path={storages} component={StoragePointPage}/>
            <PrivateRoute path={about} component={AboutPage}/>
            <PrivateRoute path={faq} component={FAQPage}/>
            <PrivateRoute path={instruction} component={InstructionPage}/>
        </Switch>
    </BrowserRouter>
};