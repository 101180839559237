import clsx from 'clsx';
import React, {useState} from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
    Drawer,
    Typography
} from "@material-ui/core";

import {MenuComponent} from "./MenuComponent";

interface ITemplateProps {
    children: any
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBarSpacer: theme.mixins.toolbar,
    appBar: {
        background: '#ffffff'
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        width: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: '#3F4A59',
    },
    drawerPaperClose: {
        width: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        // background: '#926DDE',
        background: '#4BAED0',
    },
    logo: {
        color: "white",
        fontWeight: "lighter",
        flex: 3
    },
    closeDrawerField: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
    },
}));

export const Template = (props: ITemplateProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    return (<>
        <div className={classes.root}>
            <AppBar position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: openDrawer,
                    })}>
                <Toolbar className={classes.appBar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(openDrawer && classes.hide)}
                    >
                        <MenuIcon style={{color: "black"}}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                className={clsx(classes.drawer, !openDrawer && classes.drawerClose)}
                variant="persistent"
                anchor="left"
                open={openDrawer}
                classes={{
                    paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),
                }}
            >
                <div className={classes.drawerHeader}>
                    <Typography variant="h6" noWrap className={classes.logo}>
                        Sky<span style={{fontWeight: "bold"}}>shift</span>
                    </Typography>
                    <div className={classes.closeDrawerField}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon style={{color: "white"}}/> :
                                <ChevronRightIcon style={{color: "white"}}/>}
                        </IconButton>
                    </div>
                </div>
                <MenuComponent/>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container>
                    {props.children}
                </Container>
            </main>
        </div>
    </>)
};