import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {TableContainer} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {IStoragePoint} from "@shift-mono/common";
import {IRootState} from "../../../redux/defaultState";
import {IHash} from "@shift-mono/common";
import {requestStoragePoints} from "../actions";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: '650px',
    },
    row: {
        cursor: 'pointer',
    },
    cell: {
        border: 'none'
    },
    card: {
        width: "300px"
    },
    spImage: {
        width: '160px',
    },
    container: {
        marginTop: '20px',
        boxShadow: 'none',
        backgroundColor: 'rgba(255,255,255,0) !important',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "180px",
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    }
}));

export interface IStoragePointListComponentProps {
    storagePoints: IHash<IStoragePoint>;
    loadingError: boolean;
}

const StoragePointList = (props: IStoragePointListComponentProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(requestStoragePoints());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCard = (storagePoint: IStoragePoint) => {
        return (<>
            <Card
                className={classes.card}
            >
                <CardMedia
                    src={storagePoint.getImages()[0]}
                    component={"img"}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {storagePoint.getName()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {storagePoint.getAddress().getFullAddress()}
                    </Typography>
                </CardContent>
            </Card>
        </>);
    };

    const renderTableRows = () => {
        const output: any[] = [];
        let rowBuffer: any[] = [];
        const ITEMS_PER_ROW = 3

        Object
            .keys(props.storagePoints)
            .forEach((storagePointId, index, array) => {
                rowBuffer.push(
                    <TableCell
                        className={classes.cell}
                        align="center"
                        key={storagePointId}
                    >
                        {renderCard(props.storagePoints[storagePointId])}
                    </TableCell>)

                if (rowBuffer.length === ITEMS_PER_ROW || index === array.length - 1) {
                    output.push(
                        <TableRow className={classes.row} key={index}>
                            {rowBuffer.map((item) => item)}
                        </TableRow>);
                    rowBuffer = [];
                }
            });

        return <>{output.map((item) => item)}</>;
    };

    if (props.loadingError) {
        return <Typography>Ошибка загрузки данных</Typography>
    }

    return <><TableContainer className={classes.container} component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
            <TableBody>
                {renderTableRows()}
            </TableBody>
        </Table>
    </TableContainer></>;
}

const mapStateToProps = (state: IRootState) => {
    return {
        storagePoints: state.storagePoints.storagePoints,
        loadingError: state.storagePoints.error
    }
}

export const StoragePointListComponent = connect(mapStateToProps)(StoragePointList);