import {takeEvery, call, put, cancel} from "redux-saga/effects"

import {AuthorizedRequestData, IProfile, Logger, requestProfile} from "@shift-mono/common";
import {actionTypes} from "./actionTypes"
import {ICommonAction} from "../../redux/ICommonAction";
import {refreshTokenRequest, requestTokenByPassword} from "@shift-mono/common";
import {IDataResponse} from "@shift-mono/common";
import {IToken} from "@shift-mono/common";
import {
    loginInSucceeded as loginInSucceededAction,
    loginInFailed as loginInFailedAction,
    setAccessTokenData,
    clearAccessTokenData,
    setSignIn,
    setMessage, setProfileData,
} from "./actions";

export function* authSaga() {
    yield takeEvery(actionTypes.LOGIN_IN, loginIn);
    yield takeEvery(actionTypes.LOGOUT, logout);
    yield takeEvery(actionTypes.LOGIN_IN_SUCCEEDED, loginInSucceeded);
    yield takeEvery(actionTypes.LOGIN_IN_FAILED, loginInFailed);
}

export function* verificationAndGetAccessToken() {
    Logger.d("Call verificationAndGetAccessToken");
    try {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        const expiresDate = localStorage.getItem("expiresDate");
        Logger.d(`AccessToken: ${accessToken}`);

        if (accessToken === null ||
            (expiresDate !== null
                ? Number.isNaN(Number.parseInt(expiresDate!))
                    ? true
                    : Number.parseInt(expiresDate!) < Date.now()
                : true)
        ) {
            Logger.d("Access token expired");
            Logger.d("Try to refresh access token");
            if (refreshToken === null) {
                Logger.d("Data for refresh token data is missing!");
                yield logout();
                yield cancel();
            }

            const response: IDataResponse<IToken | undefined> = yield call(refreshTokenRequest, refreshToken!);
            const token = response.getData();
            if (!token) {
                Logger.d("Can't refresh token!");
                yield logout();
                yield cancel();
            }
            yield put(setAccessTokenData(token!));
            return yield token!.getAccessToken();

        } else {
            Logger.d("Access token is valid");
            return yield accessToken;
        }
    } catch (err) {
        Logger.d("Get of access token failed");
        Logger.d(err);
        yield logout();
        yield cancel();
    }
}

export function* getUserPartnerId() {
    Logger.d("Call getUserPartnerId");
    const partnerId = localStorage.getItem("partnerId");
    return yield partnerId;
}

function* loginIn(action: ICommonAction) {
    try {
        const response: IDataResponse<IToken | undefined> = yield call(requestTokenByPassword, action.payload.login, action.payload.password);
        const token = response.getData();
        if (!token) {
            yield put(loginInFailedAction("Ошибка авторизации"));
            yield cancel();
        }

        const reqData = new AuthorizedRequestData(token!.getAccessToken())
        const profileResp: IDataResponse<IProfile | undefined> = yield call(requestProfile, reqData);
        const profile = profileResp.getData();
        if(!profile) {
            yield put(loginInFailedAction("Ошибка авторизации"));
            yield cancel();
        }

        yield put(setProfileData(profile!))
        yield put(loginInSucceededAction());
        yield put(setAccessTokenData(token!));

        yield put(setMessage());
    } catch (e) {
        Logger.d(e)
        if (e.message === "invalid_username_or_password") {
            yield put(loginInFailedAction("Неверный логин или пароль"));
        } else {
            yield put(loginInFailedAction("Ошибка авторизации"));
        }
    }
}

function* logout(action?: ICommonAction) {
    yield put(setSignIn(false));
    yield put(clearAccessTokenData());
}

function* loginInSucceeded(action: ICommonAction) {
    yield put(setSignIn(true));
}

function* loginInFailed(action: ICommonAction) {
    yield put(setSignIn(false));
    if (action.payload.message) {
        yield put(setMessage(action.payload.message))
    }
}