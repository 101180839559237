import {ICommonAction} from "../../redux/ICommonAction";
import {actionTypes} from "./actionTypes";
import {IStoragePoint} from "@shift-mono/common";

//REDUX Actions

export const addStoragePoint = (storagePoint: IStoragePoint): ICommonAction => {
    return {
        type: actionTypes.ADD_STORAGE_POINT,
        payload: {
            storagePoint
        }
    }
};

export const setErrorStatus = (errorStatus: boolean): ICommonAction => {
    return {
        type: actionTypes.SET_ERROR_STATUS,
        payload: {
            errorStatus
        }
    }
};

//REDUX-SAGA Actions

export const requestStoragePoints = (): ICommonAction => {
    return {
        type: actionTypes.GET_STORAGE_POINTS,
        payload: {
        }
    }
}