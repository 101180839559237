import {all, call, cancel, put, takeEvery} from "redux-saga/effects";
import {actionTypes} from "./actionTypes";
import {ICommonAction} from "../../redux/ICommonAction";
import {verificationAndGetAccessToken} from "../Auth";
import {
    AuthorizedRequestData,
    IDataResponse,
    IService,
    Logger,
    requestServicesByIds
} from "@shift-mono/common";
import {addService, setErrorStatus} from "./actions";

export function* servicesSaga() {
    yield takeEvery(actionTypes.GET_SERVICES_BY_IDS, getServicesByIds)
}

function* getServicesByIds(action: ICommonAction) {
    yield put(setErrorStatus(false));
    try {
        const token = yield call(verificationAndGetAccessToken);
        const servicesIds: string[] = action.payload.ids;

        if (servicesIds === null || servicesIds.length === 0) {
            Logger.d("Services ids must not be empty");
            yield put(setErrorStatus(true));
            yield cancel();
        }

        const requestData = new AuthorizedRequestData(token);
        const servicesRequestResult: IDataResponse<IService[]> = yield call(requestServicesByIds, servicesIds, requestData);

        try {
            yield all(servicesRequestResult.getData()
                .map((service: IService) => put(addService(service))));
        } catch (err) {
            Logger.d(err.message);
            yield put(setErrorStatus(true));
        }
    } catch (err) {
        Logger.d(err.message);
        yield put(setErrorStatus(true));
    }
}