import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import logo from "../../../img/logo.svg";
import {makeStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    card: {
        width: "100%",
        margin: "15px 5px 15px 5px",
    },
    logo: {
        margin: "20px 0 20px 0",
        height: "250px",
    },
    text: {
        fontSize: "19px",
        textAlign: "center",
    }
});

export const AboutCompanyComponent = () => {

    const styles = useStyles();

    return (
        <Container maxWidth="md">
            <Grid container>
                <Card className={styles.card}>
                    <CardContent>
                        <Grid container justify={"center"} direction={"row"}>
                            <img
                                className={styles.logo}
                                src={logo}
                                alt="logo"
                            />
                        </Grid>
                        <Grid container justify={"center"} direction={"row"}>
                            <div>
                                <Typography gutterBottom variant={"body1"} component={"h2"} className={styles.text}>
                                    SkyShift’s - cервис для бережного перемещения багажа, горнолыжного снаряжения, велосипедов и другого спортивного инвентаря между курортами и городами России.
                                </Typography>
                            </div>
                        </Grid>


                    </CardContent>
                </Card>
            </Grid>
        </Container>);
}