import {actionTypes} from "./actionTypes";
import {IProfile, IToken} from "@shift-mono/common";
import {ICommonAction} from "../../redux/ICommonAction";

export const loginIn = (login: string, password: string) => {
    return {
        type: actionTypes.LOGIN_IN,
        payload: {
            login,
            password,
        }
    }
};

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    }
};

export const loginInSucceeded = () => {
    return {
        type: actionTypes.LOGIN_IN_SUCCEEDED,
    }
};

export const loginInFailed = (message: string | null = null) => {
    return {
        type: actionTypes.LOGIN_IN_FAILED,
        payload: {
            message
        }
    }
};

export const setAccessTokenData = (token: IToken) => {
    return {
        type: actionTypes.SET_ACCESS_TOKEN_DATA,
        payload: {
            token
        }
    }
};

export const clearAccessTokenData = () => {
    return {
        type: actionTypes.CLEAR_ACCESS_TOKEN_DATA,
    }
};

export const setMessage = (message: string | null = null) => {
    return {
        type: actionTypes.SET_MESSAGE,
        payload: {
            message
        }
    }
};

export const setSignIn = (signedIn: boolean) => {
    return {
        type: actionTypes.SET_SIGN_IN,
        payload: {
            signedIn
        }
    }
};

export const setProfileData = (profile: IProfile): ICommonAction => {
    return {
        type: actionTypes.SET_PROFILE_DATA,
        payload: {
            profile
        }
    }
}