import {IOrder} from "@shift-mono/common";
import {actionTypes} from "./actionTypes";
import {ICommonAction} from "../../redux/ICommonAction";
import {IOrdersState, state as defaultState} from "./state";

export const reducer = (state: IOrdersState = defaultState, action: ICommonAction) => {
    switch (action.type) {
        case actionTypes.ADD_ORDER:
            const order: IOrder = action.payload.order;
            return {...state, orders: {...state.orders, [order.getId()]: order}};
        case actionTypes.SET_ERROR_STATUS:
            const errorStatus: boolean = action.payload.errorStatus;
            return {...state, error: errorStatus};
        case actionTypes.SET_LOADING_STATUS:
            const loadingStatus: boolean = action.payload.loading;
            return {...state, loading: loadingStatus};
        case actionTypes.REQUEST_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pages: {
                        ...state.pagination.pages,
                        [action.payload.page]: {
                            ids: [],
                            fetching: true
                        }
                    }
                }
            };
        case actionTypes.RECEIVE_PAGE:
            const orders: IOrder[] = action.payload.orders;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pages: {
                        ...state.pagination.pages,
                        [action.payload.page]: {
                            ids: orders.map((order) => (order.getId())),
                            fetching: false
                        }
                    }
                }
            };
        case actionTypes.SET_ITEMS_COUNT:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    itemsCount: action.payload.count,
                }
            }
        case actionTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: Number.parseInt(action.payload.page),
                }
            }
        default:
            return state;
    }
};