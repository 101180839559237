import {IHash} from "@shift-mono/common";
import {IStoragePoint} from "@shift-mono/common";

export interface IStoragePointsState {
    storagePoints: IHash<IStoragePoint>;
    loading: boolean;
    error: boolean;
}

export const state: IStoragePointsState = {
    storagePoints: {},
    loading: false,
    error: false,
}