import {actionTypes} from "./actionTypes";
import {IOrder, OrderStatus} from "@shift-mono/common";
import {ICommonAction} from "../../redux/ICommonAction";

//REDUX Actions

export const addOrder = (order: IOrder): ICommonAction => {
    return {
        type: actionTypes.ADD_ORDER,
        payload: {
            order
        }
    }
};

export const setLoadingStatus = (loading: boolean): ICommonAction => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        payload: {
            loading
        }
    }
};

export const setErrorStatus = (errorStatus: boolean): ICommonAction => {
    return {
        type: actionTypes.SET_ERROR_STATUS,
        payload: {
            errorStatus
        }
    }
};

export const receiveOrderPage = (page: number, orders: IOrder[]) => {
    return {
        type: actionTypes.RECEIVE_PAGE,
        payload: {
            page: `${page}`,
            orders,
        }
    }
}

export const requestOrderPage = (page: number, storagePointsId: string[] | null = null): ICommonAction => {
    return {
        type: actionTypes.REQUEST_PAGE,
        payload: {
            page: `${page}`,
            storagePointsId,
        }
    }
};

export const setItemsCount = (count: number): ICommonAction => {
    return {
        type: actionTypes.SET_ITEMS_COUNT,
        payload: {
            count
        }
    }
};

export const setCurrentPage = (page: number): ICommonAction => {
    return {
        type: actionTypes.SET_CURRENT_PAGE,
        payload: {
            page: `${page}`
        }
    }
};

//REDUX-SAGA Actions

export const getOrders = (): ICommonAction => {
    return {
        type: actionTypes.GET_ORDERS,
    }
};

export const getOrder = (orderId: string): ICommonAction => {
    return {
        type: actionTypes.GET_ORDER,
        payload: {orderId}
    }
};

export const updateOrderStatus = (orderId: string, status: OrderStatus): ICommonAction => {
    return {
        type: actionTypes.UPDATE_ORDER_STATUS,
        payload: {
            orderId,
            status
        }
    }
}

