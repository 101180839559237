import {actionTypes} from "./actionTypes";
import {ICommonAction} from "../../redux/ICommonAction";
import {IProfile, IToken} from "@shift-mono/common";
import {IAuthState, state as defaultState} from "./state";

export const reducer = (state: IAuthState = defaultState, action: ICommonAction) => {
    switch (action.type) {
        case actionTypes.SET_ACCESS_TOKEN_DATA:
            const token: IToken = action.payload.token;
            const expiresDate = Date.now() + (token.getExpiresIn() * 1000);

            localStorage.setItem("accessToken", token!.getAccessToken());
            localStorage.setItem("refreshToken", token!.getRefreshToken());
            localStorage.setItem("expiresDate", `${expiresDate}`);

            return Object.assign({}, state, {
                    tokenType: token.getTokenType(),
                    scope: token.getScope(),
                }
            );
        case actionTypes.CLEAR_ACCESS_TOKEN_DATA:
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expiresDate");
            localStorage.removeItem("partnerId");

            return Object.assign({}, state, {
                    tokenType: null,
                    scope: null,
                }
            );
        case actionTypes.SET_MESSAGE:
            return Object.assign({}, state, {message: action.payload.message});
        case actionTypes.SET_SIGN_IN:
            return Object.assign({}, state, {signedIn: action.payload.signedIn});
        case actionTypes.SET_PROFILE_DATA:
            const profile: IProfile = action.payload.profile;
            const partnerId = profile.getPartnerId();
            localStorage.setItem("partnerId", partnerId);
            return state;
        default:
            return state;
    }
};