export const actionTypes = {
    LOGIN_IN: "AUTH:LOGIN_IN",
    LOGOUT: "AUTH:LOGOUT",
    LOGIN_IN_SUCCEEDED: "AUTH:LOGIN_IN_SUCCEEDED",
    LOGIN_IN_FAILED:"AUTH:LOGIN_IN_FAILED",
    SET_SIGN_IN: "AUTH:SET_SIGN_IN",
    SET_MESSAGE: "AUTH:SET_MESSAGE",
    SET_ACCESS_TOKEN_DATA: "AUTH:SET_ACCESS_TOKEN_DATA",
    CLEAR_ACCESS_TOKEN_DATA: "AUTH:CLEAR_ACCESS_TOKEN_DATA",
    SET_PROFILE_DATA: "AUTH:SET_PROFILE_DATA",
};