import {cancel, put, takeEvery, select, call} from "redux-saga/effects";
import {actionTypes} from "./actionTypes";
import {ICommonAction} from "../../redux/ICommonAction";
import {IRootState} from "../../redux/defaultState";
import {Logger} from "@shift-mono/common";
import {verificationAndGetAccessToken} from "../Auth";
import {AuthorizedRequestData} from "@shift-mono/common";
import {IDataResponse} from "@shift-mono/common";
import {IClient} from "@shift-mono/common";
import {addClient, setErrorStatus, setLoadingStatus} from "./actions";
import {IClientHash} from "./state";
import {requestClientById} from "@shift-mono/common";

export function* clientsSaga() {
    yield takeEvery(actionTypes.GET_CLIENT, getClient);
}

function* getClient(action: ICommonAction) {
    yield put(setLoadingStatus(true));
    yield put(setErrorStatus(false));

    const clientId = action.payload.clientId;
    const clients: IClientHash = yield select((state: IRootState) => (state.clients.clients));
    let client: (IClient | undefined) = clients[clientId];
    if (client) {
        Logger.d("GetClientSaga: client already exist in state");
        yield put(setLoadingStatus(false));
        yield cancel();
    }

    Logger.d("GetClientSaga: get client from server");
    const token = yield call(verificationAndGetAccessToken);
    const requestData = new AuthorizedRequestData(token);
    const clientResponse: IDataResponse<IClient | undefined> = yield call(requestClientById, clientId, requestData);
    client = clientResponse.getData();
    if (client) {
        yield put(addClient(client));
        yield put(setLoadingStatus(false));
        yield cancel();
    }
    yield put(setErrorStatus(true));
    yield put(setLoadingStatus(false));
}