import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {getOrderStatusTitle, OrderStatus, OrderStatusEnv} from "@shift-mono/common";

const useStyles = makeStyles({
    card: {
        width: "100%",
        margin: "15px 5px 15px 5px",
    },
    text: {
        fontSize: "18px",
    }
});

export const InstructionComponent = () => {
    const styles = useStyles();

    const getStatus = (status: OrderStatus) => {
        switch (status) {
            case OrderStatus.New:
                return <span style={{color: "#EA4C89"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            case OrderStatus.InProgress:
                return <span style={{color: "#F6C187"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            case OrderStatus.Complete:
                return <span style={{color: "#16D17F"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            case OrderStatus.None:
                return <span style={{color: "#EA4C89"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            default:
                return <span style={{color: "#F6C187"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
        }
    };

    return (
        <Container maxWidth="md">
            <Grid container>
                <Card className={styles.card}>
                    <CardContent>
                        <Grid container justify={"center"} direction={"row"}>

                            <p><Typography gutterBottom variant={"body1"} component={"p"} className={styles.text}>
                                <b>Личный кабинет SkyShift’s</b> предназначен для управления заказами, отслеживания и изменения
                                статусов.
                            </Typography></p>
                            <Typography gutterBottom variant={"body1"} component={"h2"} className={styles.text}>
                                <b>Экран списка заказов представляет собой таблицу, отсортированных по дате и состоит из:</b>
                                <ul style={{listStyleType: "none"}}>
                                    <li>- дата заказа Клиента;</li>
                                    <li>- статус заказа;</li>
                                    <li>- ПВЗ/Отель;</li>
                                    <li>- цена заказа;</li>
                                </ul>
                                Для того, чтобы увидеть детализацию заказа необходимо нажать на один из заказов левой
                                кнопкой мыши.
                            </Typography>
                            <Typography gutterBottom variant={"body1"} component={"h2"} className={styles.text}>
                                <b>В детализации доступна развернутая информация по заказу:</b>
                                <ul style={{listStyleType: "none"}}>
                                    <li>- ФИО Клиента;</li>
                                    <li>- фотография (аватарка) Клиента;</li>
                                    <li>- номер телефона Клиента;</li>
                                    <li>- адрес отправки и доставки багажа;</li>
                                    <li>- дата создания заказа;</li>
                                    <li>- ориентировочная дата прибытия заказа;</li>
                                    <li>- ориентировочная дата выдачи багажа Клиенту\курьеру, фотографии багажа, номер заказа, тип услуги, статус заказа;</li>
                                </ul>
                            </Typography>
                            <Typography gutterBottom variant={"body1"} component={"h2"} className={styles.text}>
                                <b>Расшифровка статусов заказа:</b>
                                <ul style={{listStyleType: "none"}}>
                                    <li>{getStatus(OrderStatus.Processing)} – багаж Клиента  следует по маршруту;</li>
                                    <li>{getStatus(OrderStatus.WaitingDelivery)} – для получения этого статуса Администратору необходимо нажать на кнопку «Принять багаж». Багаж Клиента находится в Пункте выдачи/месте хранения;</li>
                                    <li>{getStatus(OrderStatus.Issued)} – для получения данного статуса необходимо нажать на кнопку «Выдать багаж». Багаж будет считаться выданным курьеру или Клиенту;</li>
                                    <li>{getStatus(OrderStatus.TransferredDelivery)} – статус, который говорит о том, что Клиент скоро доставит багаж в Отель\ПВЗ;</li>
                                    <li>{getStatus(OrderStatus.Complete)} – статус меняется Логистами сервиса SkyShift’s, после совершенного звонка Клиенту по контролю качества обслуживания.</li>
                                </ul>
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Container>);
}