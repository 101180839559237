import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import {Provider as ReduxProvider} from "react-redux";
import {ThemeProvider} from "@material-ui/core/styles";

import App from './App';
import * as serviceWorker from './serviceWorker';
import {theme} from './theme';
import {generateStore} from "./redux/storeCreator";
import {PersistGate} from "redux-persist/integration/react";

const {store, persistor} = generateStore();

ReactDOM.render(
    <>
        {/*<React.StrictMode>*/}
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <App/>
                    </ThemeProvider>
                </PersistGate>
            </ReduxProvider>
        {/*</React.StrictMode>*/}
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
