export const actionTypes = {
    ADD_ORDER: "ORDERS:ADD_ORDER",
    GET_ORDERS: "ORDERS:GET_ORDERS",
    GET_ORDER: "ORDERS:GET_ORDER",
    SET_LOADING_STATUS: "ORDERS:SET_LOADING_STATUS",
    SET_ERROR_STATUS: "ORDERS:SET_ERROR_STATUS",

    REQUEST_PAGE: "ORDERS:REQUEST_PAGE",
    RECEIVE_PAGE: "ORDERS:RECEIVE_PAGE",
    SET_ITEMS_COUNT: "ORDERS:SET_ITEMS_COUNT",
    SET_CURRENT_PAGE: "ORDERS:SET_CURRENT_PAGE",

    UPDATE_ORDER_STATUS: "ORDERS:UPDATE_ORDER_STATUS",
};