import {IClientsState} from "./state";
import {ICommonAction} from "../../redux/ICommonAction";
import {state as defaultState} from "./state";
import {IClient} from "@shift-mono/common";
import {actionTypes} from "./actionTypes";

export const reducer = (state: IClientsState = defaultState, action: ICommonAction) => {
    switch (action.type) {
        case actionTypes.ADD_CLIENT:
            const client: IClient = action.payload.client;
            return {...state, clients: {...state.clients, [client.getId()]: client}};
        case actionTypes.SET_ERROR_STATUS:
            const errorStatus: boolean = action.payload.errorStatus;
            return {...state, error: errorStatus};
        case actionTypes.SET_LOADING_STATUS:
            const loadingStatus: boolean = action.payload.loading;
            return {...state, loading: loadingStatus};
        default:
            return state;
    }
}