import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import {Redirect} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardActions, CardContent, CardMedia, TextField, Typography} from "@material-ui/core";

import logo from "../../img/logo.svg"
import {loginIn as loginAction} from "./actions";
import {IRootState} from "../../redux/defaultState";
import {home} from "../../routers/URLs";

const useStyles = makeStyles({
    root: {
        display:            'flex',
        justifyContent:     'center',
        alignItems:         'center',
        height:             '100vh',
    },
    card: {
        minWidth:           '250px',
        padding:            '20px 0 20px 0',
    },
    logo: {
        height:             '180px',
        backgroundSize:     'auto 100%'
    },
    form: {},
    button: {
        width:              '100%',
    },
    message: {
      color:                'red',
    },
});

export const AuthComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authState = useSelector((state: IRootState) => state.auth);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const renderMessage = () => {
        if(authState.message !== null){
            return <Typography className={classes.message} component="span">{authState.message}</Typography>
        }
        return <></>
    };

    if(authState.signedIn){return <Redirect to={home} />}

    return (
        <Container>
            <main className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        <CardMedia
                            className={classes.logo}
                            image={logo}
                            title="Logo"
                        />
                        {renderMessage()}
                        <form className={classes.form} noValidate autoComplete="off">
                            <div>
                                <TextField
                                    fullWidth
                                    id="standard-required"
                                    label="Логин"
                                    value={login}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setLogin(event.target.value)}}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    id="standard-required"
                                    label="Пароль"
                                    type="password"
                                    value={password}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPassword(event.target.value)}}
                                />
                            </div>
                        </form>
                    </CardContent>
                    <CardActions>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => {dispatch(loginAction(login,password))}}
                        >Войти</Button>
                    </CardActions>
                </Card>
            </main>
        </Container>
    )
};
