import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import {persistStore, persistReducer} from "redux-persist";
import {applyMiddleware, compose, createStore} from "redux";

import rootReducer from "./rootReducer";
import defaultState from "./defaultState";
import rootSaga from "./rootSaga";

export const generateStore = () => {
    // Mock function for non devtools case
    let reduxDevTools = (a: any) => { return a };
    if (process.env.NODE_ENV !== "production" && ((window as any).__REDUX_DEVTOOLS_EXTENSION__)) {
        reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();
    }

    const persistConfig = {
        key: 'root',
        storage,
        whitelist: ['auth']
    };

    const persistedReducer = persistReducer(persistConfig,rootReducer);

    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        persistedReducer,
        defaultState,
        compose(applyMiddleware(sagaMiddleware), reduxDevTools)
    );
    const persistor = persistStore(store as any);

    sagaMiddleware.run(rootSaga);
    return {store, persistor};
};