import React from "react";
import {Route} from "react-router-dom";
import {Template} from "../Template";
import {FAQComponent} from "../../components/Faq";

interface IFAQPageProps {
    match: any
}

export const FAQPage = ({match}: IFAQPageProps) => {
    return <Template>
        <Route path={`${match.path}/`} component={FAQComponent}/>
    </Template>
};