import {createMuiTheme} from "@material-ui/core/styles";

export const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#F5F6FA'
                }
            }
        }
    }
});