import {all, call, cancel, put, takeEvery} from "redux-saga/effects"
import {actionTypes} from "./actionTypes";
import {ICommonAction} from "../../redux/ICommonAction";
import {getUserPartnerId, verificationAndGetAccessToken} from "../Auth";
import {AuthorizedRequestData} from "@shift-mono/common";
import {requestPartnerStoragePoints} from "@shift-mono/common";
import {ICountableResponse} from "@shift-mono/common";
import {IStoragePoint} from "@shift-mono/common";
import {Logger} from "@shift-mono/common";
import {addStoragePoint, setErrorStatus} from "./actions";

export function* storagePointsSaga() {
    yield takeEvery(actionTypes.GET_STORAGE_POINTS, getStoragePoints)
}

function* getStoragePoints(action: ICommonAction) {
    yield put(setErrorStatus(false));
    try {
        const token = yield call(verificationAndGetAccessToken);
        // const partnerId = action.payload.partnerId;
        const partnerId = yield getUserPartnerId();
        if(partnerId === null) {
            Logger.d("PartnerId must not be null");
            yield put(setErrorStatus(true));
            yield cancel();
        }

        const requestData = new AuthorizedRequestData(token);
        const storagePointsRequestResult: ICountableResponse<IStoragePoint[]> = yield call(requestPartnerStoragePoints, partnerId, requestData);

        try {
            yield all(storagePointsRequestResult.getData()
                .map((storagePoint: IStoragePoint) => put(addStoragePoint(storagePoint))));
        } catch (err) {
            Logger.d(err.message);
            yield put(setErrorStatus(true));
        }
    } catch (err) {
        Logger.d(err.message);
        yield put(setErrorStatus(true));
    }
}