import {ICommonAction} from "../../redux/ICommonAction";
import {IService} from "@shift-mono/common";
import {actionTypes} from "./actionTypes";

//REDUX Actions

export const addService = (service: IService): ICommonAction => {
    return {
        type: actionTypes.ADD_SERVICE,
        payload: {
            service
        }
    }
};

export const setErrorStatus = (errorStatus: boolean): ICommonAction => {
    return {
        type: actionTypes.SET_ERROR_STATUS,
        payload: {
            errorStatus
        }
    }
};

//REDUX-SAGA Actions

export const requestServicesByIds = (ids: string[]): ICommonAction => {
    return {
        type: actionTypes.GET_SERVICES_BY_IDS,
        payload: {
            ids
        }
    }
}