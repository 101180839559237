import {IClient} from "@shift-mono/common";

export interface IClientHash {
    [key: string] : IClient
}

export interface IClientsState {
    clients: IClientHash;
    loading: boolean;
    error: boolean;
}

export const state: IClientsState = {
    clients: {},
    loading: false,
    error: false,
}