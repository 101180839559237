import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {withRouter} from "react-router";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableContainer,
    TablePagination,
} from "@material-ui/core";

import {requestOrderPage} from "../actions";
import {IRootState} from "../../../redux/defaultState";
import {getOrderStatusTitle, IHash, IOrder, IStoragePoint, OrderStatus, OrderStatusEnv} from "@shift-mono/common";
import {orders as ordersURL} from "../../../routers/URLs";
import {IPage} from "../state";
import Typography from "@material-ui/core/Typography";
import {requestStoragePoints} from "../../StoragePoints";

const useStyles = makeStyles((theme) => ({
    table: {
        "@media (min-width: 768px)": {
            minWidth: '650px',
        },
    },
    cell: {
        display: "flex"
    },
    cellInfo: {
        flex: 3,
    },
    cellTotal: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    cellSmallTitle: {
        fontSize: 14,
    },
    cellTitle: {
        fontSize: 16,
    },
    row: {
        cursor: 'pointer',
    },
    container: {
        marginTop: '20px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "180px",
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    }
}));

export interface IOrdersComponentProps {
    selectOrderHandler: (orderId: string) => void,
    location: any,
    history: any,
    match: any,

    currentPageNumber: number,
    currentPage: IPage,
    itemsCount: number,
    itemsPerPage: number,
    loadingError: boolean,

    relatedStoragePoints: IHash<IStoragePoint>,
}

const OrderListComponent = (props: IOrdersComponentProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useDispatch();
    const ordersState = useSelector((state: IRootState) => state.orders.orders);

    const breakpointWidth = 768;
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const resizeHandler = () => (setScreenWidth(window.innerWidth));
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler)
        }
    }, []);

    const itemsCountPerPage = props.itemsPerPage;
    const itemsCount = props.itemsCount;
    const currentPageNumber = props.currentPageNumber;

    const page = props.currentPage;
    const ordersId = page ? page.ids : null;
    const orders = ordersId
        ? ordersId
            .map((id: string) => (ordersState[id]))
            .filter((order) => order !== undefined)
        : [];
    const filterAllValue = "-1";
    const [currentFilterValue, setFilterValue] = useState(filterAllValue);

    const initStoragePoints = (): Map<string, string> => {
        const storagePoints = new Map<string, string>();
        Object
            .keys(props.relatedStoragePoints)
            .forEach((id) => {
                storagePoints.set(id, props.relatedStoragePoints[id].getName())
            })
        return storagePoints;
    }

    const storagePoints = initStoragePoints();
    const getStoragePointIdsByFilter = (filterValue: string): string[] => {
        if (filterValue === filterAllValue) {
            return Array.from(storagePoints.keys())
        }
        return [filterValue]
    }

    const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const filterValue = event.target.value as string;
        setFilterValue(filterValue);
        dispatch(requestOrderPage(currentPageNumber, getStoragePointIdsByFilter(filterValue)));
    };

    useEffect(() => {
        dispatch(requestStoragePoints());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(requestOrderPage(currentPageNumber, getStoragePointIdsByFilter(currentFilterValue)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.relatedStoragePoints])

    const dateFormatter = Intl.DateTimeFormat(
        'ru',
        {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }
    );

    const getStatus = (status: OrderStatus) => {
        switch (status) {
            case OrderStatus.New:
                return <span style={{color: "#EA4C89"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            case OrderStatus.InProgress:
                return <span style={{color: "#F6C187"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            case OrderStatus.Complete:
                return <span style={{color: "#16D17F"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            case OrderStatus.None:
                return <span style={{color: "#EA4C89"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
            default:
                return <span style={{color: "#F6C187"}}>{getOrderStatusTitle(status, OrderStatusEnv.B2B)}</span>;
        }
    };

    const renderFullSizeTable = () => {
        return (<TableContainer className={classes.container} component={Paper}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Дата создания</TableCell>
                        <TableCell align="center">Статус</TableCell>
                        <TableCell align="center">ПВЗ/Отель</TableCell>
                        {/*<TableCell align="right">Куда</TableCell>*/}
                        <TableCell align="right">Сумма</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order) => {
                        const SPToId = order.getStoragePointToId();
                        const SPFromId = order.getStoragePointFromId();
                        const SPKeys = Array.from(storagePoints.keys());

                        let storagePointTitle = "";
                        if (SPToId && SPKeys.includes(SPToId)) {
                            storagePointTitle = storagePoints.get(SPToId) ? storagePoints.get(SPToId)! : ""
                        }
                        if (SPFromId && SPKeys.includes(SPFromId)) {
                            if (storagePointTitle !== "") {
                                storagePointTitle += " / "
                            }
                            storagePointTitle += storagePoints.get(SPFromId) ? storagePoints.get(SPFromId)! : ""
                        }

                        return (
                            <TableRow
                                key={order.getId()}
                                className={classes.row}
                                onClick={() => {
                                    props.history.push(`${ordersURL}/${order.getId()}`);
                                }}>
                                <TableCell align="center">{dateFormatter.format(order.getCreatedDate())}</TableCell>
                                <TableCell align="center">{getStatus(order.getOrderStatus())}</TableCell>
                                <TableCell align="center">{storagePointTitle}</TableCell>
                                {/*<TableCell align="right">{orders[orderId].getTotalPrice()}</TableCell>*/}
                                <TableCell align="right">{order.getTotalPriceWithDiscount()}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>)
    }

    const renderCellContent = (order: IOrder) => {
        const SPToId = order.getStoragePointToId();
        const SPFromId = order.getStoragePointFromId();
        const SPKeys = Array.from(storagePoints.keys());

        let storagePointTitle = "";
        if (SPToId && SPKeys.includes(SPToId)) {
            storagePointTitle = storagePoints.get(SPToId) ? storagePoints.get(SPToId)! : ""
        }
        if (SPFromId && SPKeys.includes(SPFromId)) {
            if (storagePointTitle !== "") {
                storagePointTitle += " / "
            }
            storagePointTitle += storagePoints.get(SPFromId) ? storagePoints.get(SPFromId)! : ""
        }

        return (<div className={classes.cell}>
            <div className={classes.cellInfo}>
                <div>
                    <Typography className={classes.cellSmallTitle} color="textSecondary" gutterBottom>
                        Дата создания заказа: <span
                        style={{color: "black"}}>{dateFormatter.format(order.getCreatedDate())}</span>
                    </Typography>
                </div>
                <div>
                    <Typography className={classes.cellSmallTitle} variant="h6" component="h2" color="textSecondary">
                        Статус: {getStatus(order.getOrderStatus())}
                    </Typography>
                </div>
                <div>
                    <Typography className={classes.cellSmallTitle} variant="h6" component="h2" color="textSecondary">
                        Связанный Отель/ПВЗ: <br/> <span style={{color: "black"}}>{storagePointTitle}</span>
                    </Typography>
                </div>
            </div>
            <div className={classes.cellTotal}>
                <div>
                    <Typography variant="h6" component="h2">
                        {order.getTotalPriceWithDiscount()}
                    </Typography>
                </div>
            </div>
        </div>)
    }

    const renderSmallSizeTable = () => {
        return (<TableContainer className={classes.container} component={Paper}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableBody>
                    {orders.map((order) => {
                        return (
                            <TableRow
                                key={order.getId()}
                                className={classes.row}
                                onClick={() => {
                                    props.history.push(`${ordersURL}/${order.getId()}`);
                                }}>
                                <TableCell>{renderCellContent(order)}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>)
    }

    const renderTable = () => {
        if (screenWidth < breakpointWidth) {
            return renderSmallSizeTable();
        }

        return renderFullSizeTable();
    }

    if (props.loadingError) {
        return <Typography>Ошибка загрузки данных</Typography>
    }

    if (!page || page.fetching) {
        return <div className={classes.loaderContainer}><CircularProgress/></div>
    }

    return <>
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Фильтр по отелям/ПВЗ</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentFilterValue}
                onChange={handleFilterChange}
            >
                <MenuItem value={filterAllValue}>Все</MenuItem>
                {Array.from(storagePoints.keys()).map((id) => {
                    return <MenuItem value={id} key={id}>{storagePoints.get(id)}</MenuItem>
                })}
            </Select>
        </FormControl>
        {renderTable()}
        <TablePagination
            component="div"
            count={itemsCount}
            rowsPerPage={itemsCountPerPage}
            page={currentPageNumber}
            onChangePage={(event, page) => {
                dispatch(requestOrderPage(page, getStoragePointIdsByFilter(currentFilterValue)));
            }}
            rowsPerPageOptions={[]}
        />
    </>
};

const mapStateToProps = (state: IRootState) => {
    const currentPageNumber = state.orders.pagination.currentPage;
    return {
        currentPageNumber,
        itemsCount: state.orders.pagination.itemsCount,
        itemsPerPage: state.orders.pagination.itemsPerPage,
        currentPage: state.orders.pagination.pages[currentPageNumber],
        loadingError: state.orders.error,
        relatedStoragePoints: state.storagePoints.storagePoints,
    }
}

export const OrderListComponentWithRouter = connect(mapStateToProps)(withRouter(OrderListComponent));