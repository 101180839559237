import React from "react";
import {Route} from "react-router-dom";
import {Template} from "../Template";
import {StoragePointListComponent} from "../../components/StoragePoints";

interface IStoragePointPageProps {
    match: any
}

export const StoragePointPage = ({match}: IStoragePointPageProps) => {
    return <Template>
        <Route exact path={`${match.path}/`}><StoragePointListComponent/></Route>
    </Template>
};